window.AllianceBanner = () => {
	return {
		el: null,
		width: 0,
		init () {
			this.el = this.$refs.allianceBannerBlock.querySelector('.wp-block-heading');
			// duplicate el but with the class 'large-background-text' and as a span instead of an h2
			const largeBackgroundText = document.createElement('span');
			largeBackgroundText.classList.add('large-background-text');
			largeBackgroundText.innerHTML = this.el.innerHTML;
			this.el.parentNode.insertBefore(largeBackgroundText, this.el.nextSibling);

			// Get the initial width and set the font size of .largeBackgroundText element to 20% of the width of the parent container
			this.width = this.$refs.allianceBannerBlock.offsetWidth;
			this.setFontSize();
		}, 
		setFontSize() {
			const largeBackgroundText = this.$refs.allianceBannerBlock.querySelector('.large-background-text');
			this.$refs.allianceBannerBlock.querySelector('.large-background-text').style.fontSize = `${this.width * 0.20}px`;
		}
	};
};
